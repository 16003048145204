import React, { useState, useEffect } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface TwinFlipCardProps {
    componentData: {
        leftCard: {
            frontMedia: string;
            backMedia: string;
        };
        rightCard: {
            frontMedia: string;
            backMedia: string;
        };
        componentName: string; // Backend'e göndermek için componentName
    };
}

const TwinFlipCard: React.FC<TwinFlipCardProps> = ({ componentData }) => {
    const { leftCard, rightCard } = componentData;
    const [isLeftFlipped, setIsLeftFlipped] = useState(false);
    const [isRightFlipped, setIsRightFlipped] = useState(false);
    const [isSaved, setIsSaved] = useState(false); // Kartların sadece bir kez kaydedilmesi için state
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    // useInView hook'ları
    const { ref: leftCardRef, inView: leftCardInView } = useInView({
        triggerOnce: true, // Sadece bir kez tetiklenir
        threshold: 0.3, // Kartın %30'u görünür olduğunda tetikler
    });
    const { ref: rightCardRef, inView: rightCardInView } = useInView({
        triggerOnce: true, // Sadece bir kez tetiklenir
        threshold: 0.3, // Kartın %30'u görünür olduğunda tetikler
    });

    // Kartlar görünür olduğunda backend'e veri gönderme
    useEffect(() => {
        if ((leftCardInView || rightCardInView) && !isSaved) {
            // Sadece bir kez backend'e veri gönder
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,
                componentName: componentData.componentName,  // Bileşen adı backend'e gönderiliyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentCount: 1,  // İki kart olduğu için componentCount = 1
            })
                .then(response => {
                    console.log('Component verisi başarıyla kaydedildi:', response.data);
                    setIsSaved(true);  // Verinin sadece bir kez kaydedilmesi sağlanıyor
                })
                .catch(error => {
                    console.error('Component verisi kaydedilirken hata oluştu:', error);
                });
        }
    }, [leftCardInView, rightCardInView, isSaved, componentData.componentName]);

    // Medya render fonksiyonu
    const renderMedia = (media: string) => {
        const fileExtension = media.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return (
                <img
                    src={media}
                    alt="Media"
                    className="w-full h-full object-cover" // Kart boyutlarına tam oturacak şekilde genişlik ve yükseklik
                />
            );
        }

        if (['mp4', 'webm'].includes(fileExtension || '')) {
            return (
                <video
                    className="w-full h-full object-cover" // Video kart boyutlarına tam oturacak
                    autoPlay
                    muted
                    loop
                >
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        }

        return <p>Geçersiz medya formatı.</p>;
    };

    return (
        <div
            className="flex flex-col md:flex-row justify-center gap-8 mt-12 mx-auto"
            style={{ width: '85vw', height: '70vh' }} // Kartlar için genişlik ve yükseklik ayarları
        >
            {/* Sol Kart */}
            <ReactCardFlip isFlipped={isLeftFlipped} flipDirection="horizontal">
                <div
                    ref={leftCardRef} // leftCard için ref
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsLeftFlipped(true)}
                >
                    {/* Ön Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(leftCard.frontMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>

                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsLeftFlipped(false)}
                >
                    {/* Arka Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(leftCard.backMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>
            </ReactCardFlip>

            {/* Sağ Kart */}
            <ReactCardFlip isFlipped={isRightFlipped} flipDirection="horizontal">
                <div
                    ref={rightCardRef} // rightCard için ref
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsRightFlipped(true)}
                >
                    {/* Ön Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(rightCard.frontMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faPlus} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>

                <div
                    className="relative w-full h-[400px] md:h-full rounded-3xl overflow-hidden shadow-lg"
                    onClick={() => setIsRightFlipped(false)}
                >
                    {/* Arka Yüz */}
                    <div className="w-full h-full flex justify-center items-center">
                        {renderMedia(rightCard.backMedia)}
                    </div>
                    <button
                        className="absolute bottom-4 right-4 bg-transparent p-2 rounded-full shadow-md hover:bg-white transition-colors"
                        style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" className="text-white hover:text-black" />
                    </button>
                </div>
            </ReactCardFlip>
        </div>
    );
};

export default TwinFlipCard;
