import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import useMailStore from '../../store/mailStore';
import axios from 'axios';
import { FaEnvelope } from 'react-icons/fa'; // İkon ekledik
import { useNavigate } from 'react-router-dom';

interface PopupFormProps {
    onVerifySuccess?: () => void;
}

interface VerifyCodeResponse {
    token: string;
}
interface PopupFormProps {
    onClose?: () => void; // Form kapatma fonksiyonu opsiyonel olarak tanımlandı
    onVerifySuccess?: () => void; // Doğrulama başarılı olursa çalışacak fonksiyon
}

const VisitForm: React.FC<PopupFormProps> = ({ onVerifySuccess }) => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false); // Form açılıp kapanma durumu
    const [isFormVisible, setIsFormVisible] = useState<boolean>(true); // Formun tamamen kaybolması durumu
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [email, setEmail] = useState<string>('');
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
    const formRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        country: '',
        jobTitle: '',
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { sendVerificationEmail, verifyCode, isLoading, errorMessage: storeErrorMessage } = useMailStore();

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                setIsFormOpen(false); // Form dışına tıklayınca form kapanır
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleNext = async () => {
        if (emailRegex.test(email)) {
            setIsSendingEmail(true);
            try {
                await sendVerificationEmail(email);
                setErrorMessage('');
                setIsSendingEmail(false);
                setCurrentPage(2);
            } catch (error) {
                setIsSendingEmail(false);
                setErrorMessage('E-posta gönderilirken bir hata oluştu.');
            }
        } else {
            setErrorMessage('Geçerli bir e-posta adresi giriniz.');
        }
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFormSubmit = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
            const response = await axios.post<{ visitorId: string }>(
                `${API_URL}/visitor/save-visitor`,
                formData
            );
            const { visitorId } = response.data;
            sessionStorage.setItem('visitorId', visitorId);
            setCurrentPage(3);
        } catch (error: any) {
            setErrorMessage('Bilgiler kaydedilirken hata oluştu.');
        }
    };

    const handleVerifyCodeSubmit = async () => {
        if (verificationCode.length === 6) {
            try {
                const response = await verifyCode(email, verificationCode);
                const data = response.data as VerifyCodeResponse;
                if (data.token) {
                    Cookies.set('visitFormToken', data.token, { expires: 1 / 24 });
                    if (onVerifySuccess) {
                        onVerifySuccess();
                    }
                    setIsFormVisible(false); // Doğrulama başarılı, form ve buton tamamen kaybolacak
                } else {
                    setErrorMessage('Doğrulama başarısız oldu.');
                }
            } catch (error) {
                setErrorMessage('Doğrulama kodu hatalı.');
            }
        } else {
            setErrorMessage('6 haneli bir doğrulama kodu giriniz.');
        }
    };

    // Formun kapalı halindeyken görünecek yuvarlak buton
    const renderButton = () => (
        <div className="fixed bottom-5 right-5 z-50">
            <button
                onClick={() => setIsFormOpen(true)}
                className="w-14 h-14 rounded-full bg-red-900 text-white flex items-center justify-center shadow-lg hover:bg-red-700 transition-all"
            >
                <FaEnvelope className="w-6 h-6" /> {/* E-posta ikonu */}
            </button>
        </div>
    );

    return isFormVisible ? (
        <>
            {isFormOpen ? (
                <div className="fixed bottom-5 right-5 z-50">
                    <div className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-md z-10">
                        <div className="flex justify-between mb-6">
                            <div className={`w-full h-2 ${currentPage === 1 ? 'bg-red-900' : 'bg-gray-300'} rounded-l-lg`}></div>
                            <div className={`w-full h-2 ${currentPage === 2 ? 'bg-red-900' : 'bg-gray-300'}`}></div>
                            <div className={`w-full h-2 ${currentPage === 3 ? 'bg-red-900' : 'bg-gray-300'} rounded-r-lg`}></div>
                        </div>

                        <button onClick={() => setIsFormOpen(false)} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                            ✖
                        </button>

                        {currentPage === 1 && (
                            <div className="animate-slideIn space-y-6">
                                <h2 className="text-2xl font-semibold text-gray-800">E-posta Giriniz</h2>
                                <input
                                    type="email"
                                    placeholder="E-posta adresinizi giriniz"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />
                                {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
                                {isSendingEmail ? (
                                    <div className="flex justify-center items-center space-x-2">
                                        <div className="w-6 h-6 border-4 border-red-400 border-solid rounded-full animate-spin border-t-transparent"></div>
                                        <span>Gönderiliyor...</span>
                                    </div>
                                ) : (
                                    <button
                                        onClick={handleNext}
                                        className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-700 transition duration-300"
                                    >
                                        Devam Et
                                    </button>
                                )}

                                {/* Tailwind ile radhanji yazı tipi kullanımı */}
                                <div className="text-center mt-6">
                                    <p className="text-gray-700 font-radhanji">
                                        Damise üyeliğiniz var mı?{' '}
                                        <span
                                            className="text-gray-800 cursor-pointer font-radhanji"
                                            onClick={() => navigate('/login')}
                                        >
                                            Giriş yap
                                        </span>
                                    </p>
                                    <p className="text-gray-700 mt-2 font-radhanji">
                                        DAMISE'e katılmak ister misiniz?{' '}
                                        <a href="https://damise.com/tr/uyelik" className="text-gray-800 font-radhanji">
                                            Kayıt ol
                                        </a>
                                    </p>
                                </div>




                            </div>
                        )}


                        {/* Form kısmı */}
                        {currentPage === 2 && (
                            <div className="animate-slideIn space-y-6">
                                <h2 className="text-2xl font-semibold text-gray-800">Ziyaretçi Formu</h2>
                                <input
                                    type="text"
                                    name="companyName"
                                    placeholder="Firma"
                                    value={formData.companyName}
                                    onChange={handleFormChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="Ad"
                                    value={formData.firstName}
                                    onChange={handleFormChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Soyad"
                                    value={formData.lastName}
                                    onChange={handleFormChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="Telefon Numarası"
                                    value={formData.phoneNumber}
                                    onChange={handleFormChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />
                                <select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleFormChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                >
                                    <option value="">Ülke</option>
                                    <option value="TR">Türkiye</option>
                                    <option value="US">Amerika</option>
                                </select>
                                <input
                                    type="text"
                                    name="jobTitle"
                                    placeholder="Görev"
                                    value={formData.jobTitle}
                                    onChange={handleFormChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg"
                                />

                                <button
                                    onClick={handleFormSubmit}
                                    className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-700 transition duration-300"
                                >
                                    Kaydet ve Devam Et
                                </button>
                            </div>
                        )}

                        {/* Doğrulama kodu sayfası */}
                        {currentPage === 3 && (
                            <div className="animate-slideIn space-y-6">
                                <h2 className="text-2xl font-semibold text-gray-800">Doğrulama Kodu Giriniz</h2>
                                <input
                                    type="text"
                                    placeholder="6 haneli doğrulama kodu"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                    maxLength={6}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-red-200"
                                />
                                {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
                                <button
                                    onClick={handleVerifyCodeSubmit}
                                    className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-700 transition duration-300"
                                >
                                    Gönder
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                renderButton()
            )}
        </>
    ) : null;
};

export default VisitForm;
