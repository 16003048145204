import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import api from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { FaCalendarDays } from "react-icons/fa6";

export interface LaunchData {
  _id: string;
  launchName: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

interface SeoData {
  title: string;
  socialImage: string;
  description: string;
  url: string;
}

interface SearchComponentSectionProps {
  componentData: 'ongoing' | 'upcoming' | 'past'; // Lansman türü
}

const LaunchSearchPage: React.FC<SearchComponentSectionProps> = ({ componentData }) => {
  const [launches, setLaunches] = useState<LaunchData[]>([]);
  const [filteredLaunches, setFilteredLaunches] = useState<LaunchData[]>([]);
  const [displayedLaunches, setDisplayedLaunches] = useState<LaunchData[]>([]);
  const [seoDataMap, setSeoDataMap] = useState<{ [key: string]: SeoData }>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Yüklenme durumu

  const navigate = useNavigate();

  // Verileri getirmek için useEffect
  useEffect(() => {
    const fetchLaunches = async () => {
      try {
        setLoading(true); // Veriler yüklenirken
        const response = await api.get<LaunchData[]>(`${process.env.REACT_APP_API_URL}/launch`);
        setLaunches(response.data);
        categorizeLaunches(response.data);
        setLoading(false); // Veriler geldikten sonra yüklenme durumu kapatılıyor
      } catch (error) {
        console.error('Lansman verileri alınamadı:', error);
        setLoading(false);
      }
    };
    fetchLaunches();
  }, [componentData]);

  // Verileri kategorize etme
  const categorizeLaunches = (launches: LaunchData[]) => {
    const today = new Date();
    let filteredData: LaunchData[] = [];

    if (componentData === 'ongoing') {
      filteredData = launches.filter(
        (launch) => new Date(launch.startDate) <= today && new Date(launch.endDate) >= today
      );
    } else if (componentData === 'upcoming') {
      filteredData = launches.filter(
        (launch) => new Date(launch.startDate) > today
      );
    } else if (componentData === 'past') {
      filteredData = launches.filter(
        (launch) => new Date(launch.endDate) < today
      );
    }

    setFilteredLaunches(filteredData);
    setDisplayedLaunches(filteredData); // İlk yükleme sırasında tüm lansmanları gösteriyoruz
    // SEO verilerini gecikmeli olarak yüklemeye başla
    fetchAllSeoData(filteredData);
  };

  // SEO verilerini gecikmeli olarak getirme
  const fetchAllSeoData = async (launches: LaunchData[]) => {
    const seoMap: { [key: string]: SeoData } = {};
    for (const launch of launches) {
      try {
        const response = await api.get<SeoData>(`${process.env.REACT_APP_API_URL}/seosettings/${launch._id}`);
        seoMap[launch._id] = response.data;
      } catch (error) {
        console.error(`SEO verileri alınamadı: ${launch._id}`, error);
      }
    }
    setSeoDataMap(seoMap); // SEO verilerini yüklüyoruz
  };

  // Arama işlemi (sadece o sayfadaki lansmanlar)
  useEffect(() => {
    const filteredResults = filteredLaunches.filter((launch) =>
      launch.launchName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayedLaunches(filteredResults); // Arama sonuçlarını displayedLaunches'a set ediyoruz
  }, [searchTerm]);

  const renderLaunchCards = (launches: LaunchData[]) => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8">
        {launches.map((launch, index) => {
          const launchSeoData = seoDataMap[launch._id];
          const isUpcoming = componentData === 'upcoming'; // Sadece gelecek lansmanlar için

          return (
            <div
              key={launch._id}
              className={`relative p-6 bg-white rounded-lg shadow-md min-h-[350px] transform transition-transform duration-500 hover:scale-105 overflow-hidden`}
              style={{ transitionDelay: `${index * 150}ms` }} // Her kart için gecikme efekti
            >
              {/* Lansman görseli */}
              <div className="h-60 bg-gray-100 rounded-md flex items-center justify-center overflow-hidden shadow-sm">
                <img
                  src={launchSeoData?.socialImage || 'https://via.placeholder.com/150'}
                  alt="Launch"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                />
              </div>

              {/* Lansman başlığı ve açıklaması */}
              <h3 className="text-lg font-semibold mt-4 text-gray-900">
                {launchSeoData?.title || launch.launchName}
              </h3>

              <p className="text-gray-600 mt-2 line-clamp-2 break-words">
                {launchSeoData?.description || 'Lansman açıklaması burada yer alacak.'}
              </p>

              {/* Takvim ikonu ve başlangıç etiketi */}
              {isUpcoming && (
                <div className="flex items-center justify-start mt-4">
                  <div className="flex items-center bg-red-600 text-white px-3 py-2 rounded-full">
                    {/* FaCalendarDays ikonu */}
                    <div className="flex items-center justify-center bg-white text-gray-900 rounded-full w-10 h-10 mr-2">
                      <FaCalendarDays className="h-6 w-6" />
                    </div>
                    {/* Başlangıç Tarihi ve YAYINDA Etiketi */}
                    <span className="text-sm font-medium">
                      {new Date(launch.startDate).toLocaleDateString()} YAYINDA
                    </span>
                  </div>
                </div>
              )}

              {/* "Daha Fazlası" butonu */}
              <button
                onClick={() => navigate(`/lansman/${launchSeoData?.url || '#'}`)}
                className="text-blue-500 mt-4 underline hover:text-blue-700 float-right"
              >
                Daha fazlası...
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-4 mx-auto min-h-[70vh] max-w-[85vw]">
      <Navbar /> {/* Navbar'ı en üste ekledik */}
      <div className="mt-36"> {/* Navbar ile çakışmayı önlemek için margin-top ekledik */}
        <div className="relative w-full mb-8">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 border shadow-lg rounded-sm text-gray-700 focus:outline-none focus:ring-2"
            placeholder="Lansman Ara"
          />
        </div>

        {loading ? (
          <div>Yükleniyor...</div> // Yüklenme sırasında gösterilecek animasyon
        ) : (
          <div className="mt-4">
            {renderLaunchCards(displayedLaunches)}
          </div>
        )}
      </div>
    </div>
  );
};

export default LaunchSearchPage;
