import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

interface BannerSectionProps {
    componentData: {
        media: string;   // Banner görsel URL'si
        url: string;     // Keşfet butonu URL'si
        buttonText: string; // Buton metni
        componentName: string;
    };
}

const BannerSection: React.FC<BannerSectionProps> = ({ componentData }) => {
    const { media, url, buttonText } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    useEffect(() => {
        if (inView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentData.componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);

    return (
        <motion.div
            ref={ref}
            className="relative w-full lg:w-[85vw] h-[200px] bg-cover bg-center mb-8 flex items-center justify-center mx-auto rounded-3xl"
            style={{ backgroundImage: `url(${media})` }} // Banner görseli arka plan olarak ayarlanır
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: 'easeOut' }}
        >
            {/* Keşfet Butonu Sağ Alt Köşede, Metin Ortalanmış */}
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute bottom-6 right-6 lg:inline-flex lg:items-center lg:justify-center min-w-[200px] lg:px-16 lg:py-4 px-8 py-2 border-2 border-white text-white text-lg font-bold rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg bg-black bg-opacity-30 shadow-lg text-center" // Mobilde ve büyük ekranlarda ortalanmış metin ve buton düzeni
            >
                {buttonText || 'Keşfet'} {/* Buton metni dinamik olarak kullanılıyor */}
            </a>
        </motion.div>
    );
};

export default BannerSection;
