import React from 'react';
import { motion } from 'framer-motion';

interface SpacingSectionProps {
    componentData: {
        spacingValue: number;
    };
}

const SpacingSection: React.FC<SpacingSectionProps> = ({ componentData }) => {
    const { spacingValue } = componentData;

    return (
        <div
        //...
            className="w-full"
            style={{ marginTop: `${spacingValue}px`, marginBottom: `${spacingValue}px` }} // Dinamik boşluk oluşturma
        >
            <motion.div
                className="w-full h-[50px] bg-transparent"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
            />
        </div>
    );
};

export default SpacingSection;