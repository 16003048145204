import React, { useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface FullTextCardSectionProps {
    componentData: {
        text: string;
        componentName: string;
    };
}

const FullTextCardSection: React.FC<FullTextCardSectionProps> = ({ componentData }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
        once: false, // Her kaydırmada tetiklenecek
    });

    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor

    useEffect(() => {
        if (isInView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentData.componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            })
            .then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            })
            .catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [isInView, launchUrl]);

    return (
        <motion.div
            ref={ref}
            className="flex justify-center items-center mx-auto bg-white rounded-lg shadow-none p-4"
            style={{
                height: '45vh',  // Yükseklik 45vh
                width: '100%',   // Genişlik full
                transformOrigin: 'center', // Kart merkezde kalacak
            }}
            initial={{ opacity: 0.3 }} // Başlangıçta daha az opak
            animate={isInView ? { opacity: 1, scale: 1 } : { opacity: 0.3, scale: 0.9 }} // Görünürse opak ve tam boyut, değilse saydam ve küçültülmüş
            transition={{ duration: 1, ease: 'easeInOut' }} // Yumuşak geçiş ve daha kısa süre
        >
            <div className="max-w-[1000px] text-center p-4">
                <p
                    className="text-lg md:text-xl lg:text-2xl font-medium" // Mobil için küçük, geniş ekranlar için büyüyecek şekilde ayarlandı
                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                >
                    {componentData.text}
                </p>
            </div>
        </motion.div>
    );
};

export default FullTextCardSection;
