import React, { useState } from 'react';
import axios from 'axios';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom'; // useLocation import edildi
import Navbar from '../components/Navbar';
import lansmanLoginImage from '../assets/images/LansmanLogin.png'; // Görseli import ediyoruz

interface DamiseUserResponse {
    token: string;
    visitorId: string; // Backend'den visitorId alıyoruz
}

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation(); // Bir önceki sayfanın konumunu alır

    const handleLogin = async () => {
        if (!email || !password) {
            setErrorMessage('Email ve şifre girilmelidir.');
            return;
        }

        setIsLoading(true);
        setErrorMessage('');

        try {
            const response = await axios.post<DamiseUserResponse>(
                `${process.env.REACT_APP_API_URL}/login`,
                { email, password }
            );
            const { token, visitorId } = response.data;

            // Token ve visitorId ile işlemler yapılabilir
            console.log('Login başarılı: ', token, visitorId);

            // sessionStorage'e visitorId ekleniyor
            sessionStorage.setItem('visitorId', visitorId);

            // Başarılı giriş sonrası bir önceki sayfaya geri dön
            if (location.state?.from) {
                navigate(location.state.from); // Eğer önceki sayfa varsa oraya git
            } else {
                navigate(-1); // Yoksa bir önceki sayfaya git
            }

        } catch (error) {
            setErrorMessage('Giriş bilgileri hatalı. Tekrar deneyin.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <Navbar />
            <div className="flex bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
                {/* Sol taraf, görsel */}
                <div className="hidden md:block w-1/2 bg-gray-100">
                    <img
                        src={lansmanLoginImage}
                        alt="Lansman Login"
                        className="w-full h-full object-cover"
                    />
                </div>

                {/* Sağ taraf, giriş formu */}
                <div className="w-full md:w-1/2 p-8">
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">GİRİŞ YAP</h2>

                    {/* Email input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">* E-Posta</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="email"
                                className="form-input block w-full px-4 py-3 border border-gray-300 rounded-md"
                                placeholder="E-posta adresinizi giriniz"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <FaEnvelope className="text-gray-400" />
                            </div>
                        </div>
                    </div>

                    {/* Password input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">* Parola</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="password"
                                className="form-input block w-full px-4 py-3 border border-gray-300 rounded-md"
                                placeholder="Şifrenizi giriniz"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <FaLock className="text-gray-400" />
                            </div>
                        </div>
                    </div>

                    {/* Beni Hatırla */}
                    <div className="mb-4 flex items-center">
                        <input type="checkbox" id="remember" className="h-4 w-4 text-red-900 focus:ring-red-800 border-gray-300 rounded" />
                        <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
                            Beni Hatırla
                        </label>
                    </div>

                    {/* Error message */}
                    {errorMessage && (
                        <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
                    )}

                    {/* Login button */}
                    <button
                        onClick={handleLogin}
                        disabled={isLoading}
                        className={`w-full py-3 text-white rounded-lg bg-red-900 hover:bg-red-700 transition duration-300 ${isLoading && 'opacity-50 cursor-not-allowed'}`}
                    >
                        {isLoading ? 'Giriş Yapılıyor...' : 'Giriş yap'}
                    </button>

                    {/* Alt Bağlantılar */}
                    <div className="mt-4 flex justify-between">
                        <a href="https://damise.com/tr/sifremi-unuttum" className="text-sm text-red-900 hover:underline">
                            Şifremi Unuttum
                        </a>
                        <a href="https://damise.com/tr/uyelik" className="text-sm text-blue-500 hover:underline">
                            Kayıt ol
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
